import React, { useEffect, useMemo, useRef, Suspense, lazy, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "./store/dispatches/login";
import Loading from "./components/Loading";
import { loginParams } from "./services/helpers/login-params";
import { setRacoonMove } from "./store/entities/raccoon-move-slice";
import { setActiveBet } from "./store/entities/bet-slice";
import { setTakeStepIndex } from "./store/entities/take-step-slice";
import { startTimer } from "./services/helpers/ticket-timer-service";
import { setBalanceValue } from "./store/entities/balance-slice";
import config from "./config/config";
import { findDifficultyByCarsAndLanes } from "./services/helpers/find-difficulty-by-cars-and-lanes";
import { changeGameDifficulty } from "./store/entities/game-difficulty-slice";
import { setBetAmount } from "./store/entities/game-controls/bet-amount-slice";
import { setActiveFreeBet } from "./store/entities/free-bet-slice";
import { toggleFreeBetsState } from "./store/entities/free-bets-state-slice";
import { setFreeTicketInfo } from "./store/entities/freetickets/free-ticket-info-slice";
import { setBonusCumulative } from "./store/entities/freetickets/bonus-cumulative-slice";
import { checkValidUntilFreeTicket } from "./services/free-tickets";
import { UAParser } from 'ua-parser-js';
import translate from "./services/translation/lang";

// Lazy load components
const HandlePopupMessage = lazy(() => import("./components/popups/HandlePopupMessage"));
const GamePlay = lazy(() => import("./components/GamePlay"));
const GameControls = lazy(() => import("./components/GameControls"));

export default function Game() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const gameDivRef = useRef(null);
  const musicLoaded = useSelector(state => state.soundsLoaded);

  const [isPortrait, setIsPortrait] = useState(true);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  
  // const [loadingDelay, setLoadingDelay] = useState(true);

  useEffect(() => {
    if (login) {
      console.log("LOGIN", login)
      console.log("Version : v2.1.1")
      // set initial balance
      dispatch(setBalanceValue(login.Balance));
      dispatch({ type: 'WEBSOCKET_CONNECT', payload: { url: `${config.default.gameServers}/ws/${login.SessionID}` } });
    }
    return () => {
      dispatch({ type: 'WEBSOCKET_DISCONNECT' });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login]);

  useEffect(() => {
    dispatch(getLogin(loginParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    if (login) {      
      // this below is that music player is not unused var
      // and we need import of music player to be at least imported 
      // to set soundsLoaded to true
      import('./services/audio/music-player').then(module => {
        module.default.state('rr_trafficambience_loop');
      }).catch(err => {
        console.error("Failed to load musicPlayer.js:", err);
      });
      if (login.ActiveTicket && login) {
        const difficulty = findDifficultyByCarsAndLanes(login.Levels, login.ActiveTicket?.Cars, login.ActiveTicket?.Lanes);
        if (difficulty) {
          dispatch(changeGameDifficulty(difficulty));
        }
        if (login?.ActiveTicket?.TicketType === 3 && login.ActiveTicket?.TicketID) {
          if (login?.CurrentFreeTicketsCount && login?.CurrentFreeTicketsMoney) {
            dispatch(setActiveFreeBet({
              Status: 1,
              TicketID: login.ActiveTicket?.TicketID 
            }));
            dispatch(setFreeTicketInfo({
              noOfRounds: login.CurrentFreeTicketsCount + login?.FreeTicketsCount,
              value: login.ActiveTicket?.Bet,
              currency: login.ActiveTicket?.CurrencyID,
              freeTicketCount: login.CurrentFreeTicketsCount + login?.FreeTicketsCount,
              validUntil: login?.FreeTicketsExpiration
            }));
            // if (login?.FreeTicketsStarted) {
            //   dispatch(toggleFreeBetsState(true));
            // }
            dispatch(toggleFreeBetsState(true));
          }
        } else {
          dispatch(setActiveBet({
            Status: 1,
            TicketID: login.ActiveTicket.TicketID 
          }));
        }
       
        dispatch(setBetAmount(login.ActiveTicket.Bet));
        const loginRaccoonLine = login.ActiveTicket?.LanePos || 0;
        const firstClick = loginRaccoonLine < 1 ? true : false;
        let raccoonPosition;
        if (!loginRaccoonLine) {
          raccoonPosition = 0;
        } else {
          raccoonPosition = 115 + (loginRaccoonLine - 1) * 155;
        }
        dispatch(setRacoonMove({
          position: raccoonPosition,
          firstClick: firstClick,
          raccoonIndex: loginRaccoonLine - 1,
          lineIndex: loginRaccoonLine
        }));
        dispatch(setTakeStepIndex(loginRaccoonLine + 1));
        
        // starts ticket expiration timer
        startTimer(dispatch,Date.now() + (login?.ActiveTicket?.ExpiresInS * 1000) ||
           Date.now() + (115 * 1000));
      }

      if (login && !login?.ActiveTicket && login?.FreeTicketsCount && login?.FreeTicketsMoney) {
        dispatch(setFreeTicketInfo({
          noOfRounds: login.FreeTicketsCount,
          value: login.FreeTicketsMoney,
          currency: login.CurrencyID,
          freeTicketCount: login.FreeTicketsCount,
          validUntil: login?.FreeTicketsExpiration
        }));
        if (login?.FreeTicketsStarted) {
          dispatch(toggleFreeBetsState(true));
        }
      }
      
      // sets culumative free tickets win
      if (login?.FreeTicketsTotalWin) {
        dispatch(setBonusCumulative(login.FreeTicketsTotalWin))
      }

      // checks for free tickets bonus modal validUntil timestamp
      checkValidUntilFreeTicket(login?.FreeTicketsExpiration);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[login]);

  // Set a timeout to delay the removal of the loading page
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoadingDelay(false);
  //   }, 2000); 

  //   return () => clearTimeout(timer);
  // }, []);

  const checkOrientation = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    const parser = new UAParser();
    const deviceType = parser.getDevice().type; // "mobile", "tablet", or undefined
    setIsMobileOrTablet(deviceType === "mobile" || deviceType === "tablet");

    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  useEffect(() => {
    if (isMobileOrTablet && !isPortrait) {
      document.body.classList.add("landscape");
    } else {
      document.body.classList.remove("landscape");
    }

    return () => {
      document.body.classList.remove("landscape");
    };
  }, [isMobileOrTablet, isPortrait]);

  return useMemo(
    () => (
      <div className={"game " + (isMobileOrTablet && !isPortrait ? "landscape" : "")} ref={gameDivRef}>
        {(login && musicLoaded) ? (
          <Suspense fallback={<Loading />}>
            <HandlePopupMessage />
            {isMobileOrTablet && !isPortrait ? <div className="landscape-message">{translate('please_play_the_game_in_portait_mode')}</div> : null}
            <GamePlay login={login} />
            <GameControls gameDivRef={gameDivRef} />
          </Suspense>
        ) : (
          <Loading />
        )}
      </div>
    ),
    [login, musicLoaded, isMobileOrTablet, isPortrait]
  );
}
