import store from "../../store/configure-store";
import { setPopupMessage } from "../../store/entities/popup-message-slice";
import { GENERIC_ERROR_MESSAGE } from "../helpers/generic-messages";
import translate from "../translation/lang";

/**
 * validateBetResponse
 * 
 * @param {obj || null} response 
 */
export const validateFreeBetResponse = (response) =>  {
  try {
    if (response.Status !== 1 ) {
      if (response.Message) {
        store.dispatch(setPopupMessage({
          message: response.Message === 'Bet already active' ? translate('bet_already_active') : response.Message,
          delay: 5000
        }));
        return false;
      }
    }
    return true;
  } catch (error) {
    console.error("Error from validate Free Bet Response: " ,error);
    store.dispatch(setPopupMessage({
      message: GENERIC_ERROR_MESSAGE,
      delay:  5000
    }));
    return false;
  }
}
