import { createSlice } from "@reduxjs/toolkit";

const playerBonusHistorySlice = createSlice({
  name: "playerBonusHistory",
  initialState: {
    playerBonusHistoryList: [],
  },
  reducers: {
    setPlayerBonusHistoryList(state, action) {
      state.playerBonusHistoryList = action.payload;
    },
  }
});

export const { setPlayerBonusHistoryList } = playerBonusHistorySlice.actions;
export default playerBonusHistorySlice.reducer;
